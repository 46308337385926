<template>
    <div class="appraise">
        <div class="tilte">
            <div></div>
            <span>{{$t('mine.elses.pinjia')}}</span>
        </div>
        <div class="pinfen">
            <div class="title">*{{$t('mine.elses.pinfen')}}</div>
            <div class="title">
                <el-rate v-model="evaluateScore" :colors="['#000', '#000', '#000']" void-color="#000" text-color="#000"
                    score-template="{evaluateScore}">
                </el-rate>
            </div>
            <div class="title">*{{$t('mine.elses.neirong')}}</div>
            <textarea class="textarea"  v-model="textarea" :placeholder="$t('mine.elses.pinjia')"></textarea>
            <div class="addbtn" @click="submit">{{$t('button.tijiao')}}</div>
        </div>
    </div>
</template>

<script>
import {evaluateOrder} from '@/api/main.js'
export default {
    data() {
        return {
            evaluateScore: 0,
            textarea:'',
            orderId:''
        }
    },
    mounted() {
        this.orderId = this.$route.query.id;
    },
    methods: {
        submit(){
            
            if(this.evaluateScore==0){
                this.$message({
                    message: this.isChinese() ? '请先评分':'Please score first',
                    type: 'warning'
                    });
                    return
            }
            if(!this.textarea){
                    this.$message({
                    message: this.isChinese() ? '请先输入评论内容':'Please enter your comments first',
                    type: 'warning'
                    });
                
                return
            }
            evaluateOrder({
                orderId:this.orderId,
                evaluateScore:this.evaluateScore,
                evaluateContent:this.textarea
            }).then(res=>{
                if(res.code==0){
                    if(this.isChinese()){
                        this.$message({
                            message: '评论成功',
                            type: 'success'
                        });
                    }else{
                        this.$message({
                            message: 'Review success',
                            type: 'success'
                        });
                    }
                    this.$router.back();
                }else{
                    this.showMessage(res)
                }
            })
        }
    }
}
</script>
<style lang="scss">
.appraise {
    width: 880px;

    .tilte {

        display: flex;
        align-items: center;
        padding: 9px 0px;
        padding-top: 0px;

        div {
            width: 2px;
            height: 24px;
            background: #333333;
            margin-right: 10px;
        }

        span {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
    }

    .pinfen {
        overflow: hidden;
        height: 450px;
        transition: height 1s ease;
        margin-top: 20px;

        .h3 {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            padding: 20px 0px;
            padding-bottom: 0px;
            border-top: 1px solid #ECECEC;
        }

        .title {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 25px;
            padding: 20px 0px;

            .el-rate__icon {
                font-size: 25px !important;
            }
        }

        .textarea {
            padding: 10px;
            width: 100%;
            resize: none;

            height: 100px;
            margin: 20px 0px;
            margin-top: 0px;
            box-sizing: border-box;
        }

        .addbtn {
            margin-right: 20px;
            margin-top: 20px;
            width: 148px;
            height: 50px;
            border: 1.5px solid transparent;
            border-radius: 0;
            white-space: nowrap;
            transition: all 0.3s ease;
            border: 2px solid #000;
            text-align: center;
            padding: 15px 0px;

            img {
                width: 16px;
                height: 16px;
                vertical-align: middle;
            }
        }

        .addbtn:hover {
            background: #000;
            color: #fff;
            cursor: pointer;
        }
    }
}
</style>
